import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { CheckIcon } from 'components/Form/CheckIcon';

const Button = styled.button`
  box-sizing: border-box;
  position: relative;
  background: ${props =>
    props.disabled ? 'var(--toggle-disabled)' : props.active ? 'var(--toggle-active)' : 'var(--toggle-inactive)'};
  border: 5px solid
    ${props =>
      props.disabled ? 'var(--toggle-disabled)' : props.active ? 'var(--toggle-active)' : 'var(--toggle-inactive)'};
  border-radius: var(--size-sm);
  width: var(--size-xl-plus);
  height: var(--size-lg);
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  padding: var(--size-xxxs);
  appearance: none;
  ${props =>
    props.small &&
    css`
      transform: scale(0.8);
    `}

  &:focus {
    outline: none;
  }

  @media print {
    display: none;
  }
`;

const Thumb = styled.div`
  position: absolute;
  top: 0;
  left: ${props => (props.active ? '16px' : 0)};
  width: 14px;
  height: 14px;
  transition: all ${props => props.theme.motion.quick} ${props => props.theme.motion.easeIn};
  background: var(--white);
  color: ${props =>
    props.active ? (props.disabled ? 'var(--toggle-inactive)' : 'var(--toggle-active)') : 'transparent'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OnOffSwitch = ({ small, active, as, disabled, ...buttonProps }) => (
  <Button as={as} active={active} small={small} disabled={disabled} {...buttonProps}>
    <Thumb active={active}>
      <CheckIcon pixelSize={8} />
    </Thumb>
  </Button>
);

OnOffSwitch.propTypes = {
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  small: PropTypes.bool,
  as: PropTypes.string,
};

export default OnOffSwitch;
