export const InputType = {
  DROPDOWN: 'dropdown',
  TEXT: 'text',
  EMPTY: 'empty',
  CONTACT_CAVERION: 'CONTACT_CAVERION',
};

export const FORM_ONLY_FIELDS = {
  supplierInput: InputType.DROPDOWN,
  categoryInput: InputType.DROPDOWN,
  sendSMSInput: true,
  sendEmailInput: true,
  assignmentSettings: true,
};

export const UI_ONLY_FIELDS = ['detailedStatus', 'orderType', 'system', 'supplierId'];
