import uniq from 'lodash/uniq';
import toPairs from 'lodash/toPairs';
import fromPairs from 'lodash/fromPairs';
import difference from 'lodash/difference';
import mergeWith from 'lodash/mergeWith';
import pickBy from 'lodash/pickBy';
import union from 'lodash/union';
import endsWith from 'lodash/endsWith';
import { isCaverionAdminRole } from '../Data/profileData';

export const ALLOW_EXPERIMENTAL_FEATURES = String(process.env.REACT_APP_ALLOW_EXPERIMENTAL_FEATURES) === 'true';
export const ALLOW_BETA_FEATURES = String(process.env.REACT_APP_ALLOW_BETA_FEATURES) === 'true';
export const ALLOW_QA_FEATURES = String(process.env.REACT_APP_ALLOW_QA_FEATURES) === 'true';

export const getPartnerNumbers = profile =>
  uniq([...Object.keys(profile.permissions || {}), ...(profile.partnerPermissions || [])]);

export const hasPartnerPermission = (profile, partnerNumber) => {
  if (!profile || !profile.partnerPermissions || !partnerNumber) {
    return false;
  }

  return profile.partnerPermissions.includes(partnerNumber);
};

export const filterPathByPermissions = (path, partnerNumber, profile) => {
  // Partner permission always grants access to entire path
  if (hasPartnerPermission(profile, partnerNumber)) {
    return path;
  }

  const permissions = profile.permissions && profile.permissions[partnerNumber];
  if (!permissions) {
    return [];
  }

  // Remove functional locations from path until it only contains authorized functional locations
  let authorizedPath = path;
  while (authorizedPath.length) {
    if (permissions.includes(authorizedPath[0])) {
      break;
    }

    authorizedPath = authorizedPath.slice(1);
  }

  return authorizedPath;
};

export const getAllPermissionsFromModel = model => {
  const permissions = mergeWith(
    {},
    model.permissions,
    model.inactivePermissions,
    model.addedFunctionalLocations,
    (a, b) => {
      if (Array.isArray(a)) {
        return uniq(a.concat(b));
      }
    }
  );

  return pickBy(permissions, functionalLocations => functionalLocations?.length);
};

export const deriveEditedPermissions = model => {
  const {
    partnerPermissions = [],
    deletedFunctionalLocations = {},
    addedPartnerPermissions = [],
    deletedPartnerPermissions = [],
  } = model;

  const updatedPartnerPermissions = difference(
    union(partnerPermissions, addedPartnerPermissions),
    deletedPartnerPermissions
  );

  const allPermissions = getAllPermissionsFromModel(model);

  const updatedPermissions = fromPairs(
    toPairs(allPermissions)
      .filter(([partnerNumber]) => !updatedPartnerPermissions.includes(partnerNumber))
      .map(([partnerNumber, functionalLocations]) => [
        partnerNumber,
        difference(functionalLocations, deletedFunctionalLocations[partnerNumber]),
      ])
      .filter(([, functionalLocations]) => functionalLocations.length)
  );

  return {
    permissions: updatedPermissions,
    partnerPermissions: updatedPartnerPermissions,
  };
};

export const getEnabledTeasers = (features, featureTeasers = []) => ({
  featureTeasers: featureTeasers.filter(teaser => !features[teaser]),
});

export const selectAuthorizedPartnerNumber = (partnerNumbers, profile, path) => {
  if (!partnerNumbers) {
    return undefined;
  }

  const partnerPermissions = profile.partnerPermissions || [];
  const permissions = profile.permissions || {};
  return partnerNumbers.find(
    partnerNumber =>
      partnerPermissions.includes(partnerNumber) ||
      (partnerNumber in permissions && path.some(fl => permissions[partnerNumber].includes(fl)))
  );
};

export const isCaverionNetOrComUser = email => email?.endsWith('caverion.com') || email?.endsWith('caverion.net');

export const isExperimentalFeaturesEnabled = getState =>
  ALLOW_EXPERIMENTAL_FEATURES && getState()?.profile?.profile?.features?.experiments === true;

export const RESTRICTED_LOCATIONS = {
  10465384: {
    'FIXX000020-001': true, // BMS demo location (North Office)
  },
};

export const canAddRestrictedPermission = profile => {
  return (
    isCaverionAdminRole(profile.role) ||
    endsWith(profile.username, '@caverion.com') ||
    Boolean(profile.username.match(/^demo\.(fi|se|no|dk|lt|de|ru|at|ee|development|en)@caverion.net/g))
  );
};

/**
 * Defines correct functional locations or partner numbers to be used when fetching data,
 * based on different kinds of permissions, and if is data fetched for portfolio or building
 */
export const getAuthorizationParams = (
  { profile, partnerNumber, functionalLocationId },
  options = { returnEmptyOnAllPartnerNumbers: false }
) => {
  if (functionalLocationId) {
    return { functionalLocations: [functionalLocationId] };
  }
  if (hasPartnerPermission(profile, partnerNumber)) {
    return { partnerNumbers: [partnerNumber] };
  }
  if (partnerNumber === 'all') {
    if (options.returnEmptyOnAllPartnerNumbers) {
      return {};
    }
    return {
      partnerNumbers: [...profile.partnerPermissions],
      functionalLocations: Object.values(profile.permissions).flat(),
    };
  }
  return { functionalLocations: [...profile.permissions[partnerNumber]] };
};
