import { createReducerFromMapping } from 'redux/utils/index';
import merge from 'lodash/merge';
import uniqBy from 'lodash/uniqBy';
import MasterDataService from 'services/masterData';

const initialState = {
  equipments: {},
  subEquipments: {},
  totalEquipment: {},
  equipmentTexts: {},
};

const emptyArray = [];

const mapEquipment = ({ shortText = '', longText = '', text, ...equipment }) => ({
  ...equipment,
  text: text ?? [longText.indexOf(shortText) === 0 ? '' : shortText, longText].join(' '),
});

export const SET_TOTAL_EQUIPMENT = 'CUSTOMER_PLATFORM/Equipment/SET_TOTAL_EQUIPMENT';

export const setTotalEquipment = (functionalLocation, totalEquipment) => ({
  type: SET_TOTAL_EQUIPMENT,
  functionalLocation,
  totalEquipment,
});

export const LOAD_ONE = 'CUSTOMER_PLATFORM/Equipment/LOAD_ONE';
export const LOAD_ONE_SUCCESS = 'CUSTOMER_PLATFORM/Equipment/LOAD_ONE_SUCCESS';
export const LOAD_ONE_FAIL = 'CUSTOMER_PLATFORM/Equipment/LOAD_ONE_FAIL';

export const loadEquipment = (functionalLocation, equipmentNumber, superordinate) => {
  const params = {
    path: [functionalLocation.functionalLocation],
    equipmentNumber,
    ...(superordinate && { equipmentSuperordinate: superordinate }),
  };

  return async dispatch => {
    dispatch({ type: LOAD_ONE });
    try {
      const result = await MasterDataService.equipment(params);

      return dispatch({
        type: LOAD_ONE_SUCCESS,
        page: undefined,
        functionalLocation: functionalLocation.functionalLocation,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_ONE_FAIL,
        functionalLocation: functionalLocation.functionalLocation,
        error,
      });
    }
  };
};

export const LOAD = 'CUSTOMER_PLATFORM/Equipment/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/Equipment/LOAD_FAIL';

export const loadEquipments = (functionalLocationId, includeAllLevels) => {
  const params = {
    ...(includeAllLevels ? { path: [functionalLocationId] } : { functionalLocations: [functionalLocationId] }),
  };

  return async dispatch => {
    dispatch({ type: LOAD });
    try {
      const result = await MasterDataService.equipment(params);

      return dispatch({
        type: LOAD_SUCCESS,
        functionalLocation: functionalLocationId,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FAIL,
        functionalLocation: functionalLocationId,
        error,
      });
    }
  };
};

export const LOAD_TEXTS = 'CUSTOMER_PLATFORM/Equipment/LOAD_TEXTS';
export const LOAD_TEXTS_SUCCESS = 'CUSTOMER_PLATFORM/Equipment/LOAD_TEXTS_SUCCESS';
export const LOAD_TEXTS_FAIL = 'CUSTOMER_PLATFORM/Equipment/LOAD_TEXTS_FAIL';

export const loadEquipmentTexts = equipmentIds => {
  return async dispatch => {
    dispatch({ type: LOAD_TEXTS });
    try {
      const result = await MasterDataService.equipmentTexts(equipmentIds);

      return dispatch({
        type: LOAD_TEXTS_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_TEXTS_FAIL,
        error,
      });
    }
  };
};

export const LOAD_SUBEQUIPMENT = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT';
export const LOAD_SUBEQUIPMENT_SUCCESS = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT_SUCCESS';
export const LOAD_SUBEQUIPMENT_FAIL = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT_FAIL';

export const LOAD_SUBEQUIPMENT_PART1 = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT_PART1';
export const LOAD_SUBEQUIPMENT_PART2 = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT_PART2';
export const LOAD_SUBEQUIPMENT_PART1_SUCCESS = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT_PART1_SUCCESS';
export const LOAD_SUBEQUIPMENT_PART2_SUCCESS = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT_PART2_SUCCESS';
export const LOAD_SUBEQUIPMENT_PART1_FAIL = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT_PART1_FAIL';
export const LOAD_SUBEQUIPMENT_PART2_FAIL = 'CUSTOMER_PLATFORM/Equipment/LOAD_SUBEQUIPMENT_PART2_FAIL';

export const loadSubEquipments = (functionalLocationId, equipmentSuperordinate) => {
  return async dispatch => {
    dispatch({ type: LOAD_SUBEQUIPMENT });

    const equipmentSuperordinates = Array.isArray(equipmentSuperordinate)
      ? equipmentSuperordinate
      : [equipmentSuperordinate];
    try {
      const params = {
        path: [functionalLocationId],
      };
      const result = await Promise.all(
        equipmentSuperordinates.map(equipmentSuperordinate =>
          MasterDataService.equipment({ equipmentSuperordinate, ...params })
        )
      );

      return dispatch({
        type: LOAD_SUBEQUIPMENT_SUCCESS,
        functionalLocation: functionalLocationId,
        equipmentSuperordinates,
        result: result.flat(),
      });
    } catch (error) {
      return dispatch({
        type: LOAD_SUBEQUIPMENT_FAIL,
        functionalLocation: functionalLocationId,
        equipmentSuperordinates,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [SET_TOTAL_EQUIPMENT]: (state, action) => ({
      ...state,
      totalEquipment: { ...state.totalEquipment, [action.functionalLocation]: action.totalEquipment },
    }),
    [LOAD_ONE]: (state, action) => ({
      ...state,
    }),
    [LOAD_ONE_SUCCESS]: (state, action) => {
      return {
        ...state,
        equipments: merge({}, state.equipments, {
          [action.functionalLocation]: uniqBy(
            (state.equipments[action.functionalLocation] || emptyArray).concat(
              action.result.map(equipment => ({
                ...mapEquipment(equipment),
                page: action.page,
              }))
            ),
            'equipmentNumber'
          ),
        }),
      };
    },
    [LOAD_ONE_FAIL]: (state, action) => ({
      ...state,
      equipments: merge({}, state.equipments, {
        [action.functionalLocation]: action.error,
      }),
    }),
    [LOAD]: (state, action) => ({
      ...state,
    }),
    [LOAD_SUCCESS]: (state, action) => {
      const equipments = state.equipments[action.functionalLocation];
      const existingEquipmentData =
        equipments &&
        equipments.map(equipment => ({
          ...equipment,
          totalMatches: action.result.length > 0 ? action.result[0].totalMatches : 0,
        }));

      return {
        ...state,
        equipments: merge({}, state.equipments, {
          [action.functionalLocation]: uniqBy(
            (existingEquipmentData || emptyArray).concat(action.result.map(mapEquipment)),
            'equipmentNumber'
          ),
        }),
      };
    },
    [LOAD_FAIL]: (state, action) => ({
      ...state,
      equipments: merge({}, state.equipments, {
        [action.functionalLocation]: action.error,
      }),
    }),

    [LOAD_SUBEQUIPMENT]: (state, action) => ({
      ...state,
    }),
    [LOAD_SUBEQUIPMENT_SUCCESS]: (state, action) => ({
      ...state,
      subEquipments: merge(
        {},
        state.subEquipments,
        Object.fromEntries(
          action.equipmentSuperordinates.map(equipmentSuperordinate => [
            equipmentSuperordinate,
            uniqBy(
              (state.subEquipments[equipmentSuperordinate] || emptyArray).concat(
                action.result
                  .filter(equipment => equipment.equipmentSuperordinate === equipmentSuperordinate)
                  .map(mapEquipment)
              ),
              'equipmentNumber'
            ),
          ])
        )
      ),
    }),
    [LOAD_SUBEQUIPMENT_FAIL]: (state, action) => ({
      ...state,
      subEquipments: merge({}, state.subEquipments, {
        [action.functionalLocation]: action.error,
      }),
    }),
    [LOAD_SUBEQUIPMENT_PART1_FAIL]: (state, action) => ({
      ...state,
      subEquipments: merge({}, state.subEquipments, {
        [action.functionalLocation]: action.error,
      }),
    }),
    [LOAD_SUBEQUIPMENT_PART2_FAIL]: (state, action) => ({
      ...state,
      subEquipments: merge({}, state.subEquipments, {
        [action.functionalLocation]: action.error,
      }),
    }),

    [LOAD_TEXTS_SUCCESS]: (state, action) => ({
      ...state,
      equipmentTexts: { ...state.equipmentTexts, ...action.result },
    }),
  },
  initialState
);
