import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

export const equipment = async params => {
  const limit = 100;
  let offset = 0;

  const results = [];
  let fetchMore;
  do {
    const result = await ApiRequest.post({
      apiPath: `${SERVICE_API_PATH}/equipment/find`,
      data: { ...params, limit, offset },
    });
    results.push(...result);
    offset += limit;
    fetchMore = result.length === limit;
  } while (fetchMore);
  return results;
};

export const equipmentTexts = equipmentIds =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/equipment/texts`,
    data: { equipmentNumber: equipmentIds },
  });
