import InputRow from 'components/Form/InputRow';
import styled from 'styled-components';

export const Label = styled.label`
  display: block;
  font-weight: ${props => props.theme.font.weight.normal};
  font-size: ${props => props.theme.font.size.xxs};
  color: ${props => props.theme.colors.black};

  &::after {
    content: '*';
    color: ${props => props.theme.colors.radicalRed};
  }
`;

export const CommentInputRow = styled(InputRow)`
  margin: 0;
  button {
    margin-top: 1.5em;
  }

  textarea {
    box-shadow: none;
  }

  & > label {
    line-height: 1em;
    display: inline-block;
    margin-bottom: 0.5em;
  }

  &:not(:first-child) {
    margin-top: 0.5em;
  }
`;
