import React from 'react';
import PropTypes from 'prop-types';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import RadioButton from 'components/Form/RadioButton';
import { useTranslations } from 'decorators/Translations/translations';
import { CommentInputRow, Label } from './RadioInputGroup.styled';

const RadioInputGroup = ({ label, value, onChange, options, disabled }) => {
  const [t] = useTranslations();
  return (
    <CommentInputRow>
      {label && <Label>{label}</Label>}
      <RadioButtonGroup name="status" value={value} onChange={onChange}>
        {options.map(({ label, value }, idx) => (
          <RadioButton label={t(label)} value={value} key={idx} disabled={disabled} />
        ))}
      </RadioButtonGroup>
    </CommentInputRow>
  );
};

RadioInputGroup.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
};

export default RadioInputGroup;
