import React, { useState, useMemo } from 'react';
import MasterDataService from 'services/masterData';

type ResolveUsername = { firstName: string; lastName: string; email: string };
type ResolveUsernameResponse = { username: string; isSsoUser: boolean; isExistingUser: boolean };

export const useResolveUsername = () => {
  const [data, setData] = useState<ResolveUsernameResponse>();

  const resolveUsername = React.useCallback(({ firstName, lastName, email }: ResolveUsername) => {
    MasterDataService.resolveSsoDomainUsername({ firstName, lastName, email }).then(setData);
  }, []);

  return useMemo(
    () => ({
      username: data?.username,
      isSsoUser: data?.isSsoUser,
      isExistingUser: data?.isExistingUser,
      resolveUsername,
    }),
    [data, resolveUsername]
  );
};
