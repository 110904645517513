import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import env from './service/env';
import title from './title/title';
import alarm from './iot/alarm';
import sensors from './iot/sensors';
import profile from './profile/profile';
import values from './iot/values';
import notice from './iot/notice';
import utilizationRate from './iot/utilizationRate';

import document from './document/document';
import folder from './document/folder';
import file from './document/file';
import navigation from './navigation/navigation';
import plannedMaintenance from './service/plannedMaintenance';
import serviceOrders from './service/serviceOrders';
import serviceModule from './service/serviceModule';
import maintenancePlans from './service/maintenancePlans';
import serviceRequests from './service/serviceRequests';
import common from './common/common';
import shortUrls from './common/shortUrls';

import application from './containers/application';
import partnerOverview from './containers/partner-overview';
import functionalLocationContainer from './containers/functional-location';
import buildingContainer from './containers/building';
import buildingAdminContainer from 'redux/modules/containers/buildingAdmin';
import equipmentContainer from './containers/equipment';
import energy from './iot/energy';
import KPIContainer from './containers/kpi';
import KPIEnergyContainer from './containers/kpi-energy';
import equipmentsModule from './containers/equipments-module';
import fileupload from './common/fileupload';
import announcement from './announcement/announcement';
import feedback from './feedback/feedback';
import buildingMeta from './buildingConfig/buildingMeta';
import functionalLocationOpenHours from './buildingConfig/functionalLocationOpenHours';
import sensorAlarms from './iot/sensorAlarms';
import userManual from './userManual/userManual';
import customView from './customView/customView';

import customer from './customer/customer';
import equipments from './customer/equipments';
import functionalLocations from './customer/functionalLocations';
import partnerImage from './customer/partnerImage';
import sensorHierarchy from './customer/sensorHierarchy';
import sensorMeta from './customer/sensorMeta';
import partnerMeta from './customer/partnerMeta';
import tag from './tag/tag';
import generalLoading from './general/loading';
import exportSensorData from './iot/exportSensorData';
import contact from './contact/contact';
import config from './config/config';
import dataImport from './dataProcessing/import_sensor_values';
import customCharts from './iot/customCharts';
import analyticsFunctions from './analyticsFunction/analyticsFunctions';
import analyticsFunctionLogs from './analyticsFunction/analyticsFunctionLogs';
import sri from './sri/sri';

const createRootReducer = routerHistory =>
  combineReducers({
    alarm,
    analyticsFunctionLogs,
    analyticsFunctions,
    announcement,
    application,
    buildingAdminContainer,
    buildingContainer,
    buildingMeta,
    common,
    config,
    contact,
    customCharts,
    customer,
    customView,
    dataImport,
    document,
    energy,
    env,
    equipmentContainer,
    equipments,
    equipmentsModule,
    exportSensorData,
    feedback,
    file,
    fileupload,
    folder,
    functionalLocationContainer,
    functionalLocationOpenHours,
    functionalLocations,
    generalLoading,
    KPIContainer,
    KPIEnergyContainer,
    maintenancePlans,
    navigation,
    notice,
    partnerImage,
    partnerMeta,
    partnerOverview,
    plannedMaintenance,
    profile,
    router: connectRouter(routerHistory),
    sensorAlarms,
    sensorHierarchy,
    sensorMeta,
    sensors,
    serviceModule,
    serviceOrders,
    serviceRequests,
    shortUrls,
    sri,
    tag,
    title,
    userManual,
    utilizationRate,
    values,
  });

export default createRootReducer;
