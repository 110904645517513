import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Content = styled.div`
  color: var(--body-text-fg);
  font-size: ${props => props.theme.fontSize.sm};
  line-height: ${props => props.theme.font.lineHeight.lg};
  text-align: ${props => props.center && 'center'};
`;

const ContentList = styled.ol`
  width: fit-content;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: ${props => props.theme.fontSize.sm};
  line-height: ${props => props.theme.font.lineHeight.lg};
`;
ContentList.displayName = 'ContentList';

const ContentRow = styled.li``;
ContentRow.displayName = 'ContentRow';

const ConfirmationModalContent = ({ children, additionalInfoText }) => {
  const showAdditionalInfoAsList = Array.isArray(additionalInfoText) && additionalInfoText.length > 1;
  return (
    <Content center={Boolean(additionalInfoText && !showAdditionalInfoAsList)}>
      {showAdditionalInfoAsList ? (
        <ContentList>
          {additionalInfoText.map((text, idx) => (
            <ContentRow key={idx}>{text}</ContentRow>
          ))}
        </ContentList>
      ) : (
        additionalInfoText
      )}
      {children}
    </Content>
  );
};

ConfirmationModalContent.propTypes = {
  children: PropTypes.node,
  additionalInfoText: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
};
export default ConfirmationModalContent;
